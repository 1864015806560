import React from 'react';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
            <ToastContainer />

    <div className='header'>
        <p>Phone: 07722260340</p>
        <p>Email: sales@shuaa.company</p>
      </div>
    <div className="App">
      
        <div className='container'> 

          <div className='image-con'>
            <img src='/logo3.png'/>

          </div>
        <div className='social' onClick={()=>{
           navigator.clipboard.writeText("07722260340")
           toast.info("Copy Phone To Clip Board")
        }}>
            <img  src='/1.png'/>
            <p>Phone Number</p>
          </div>
          <div className='social'  onClick={()=> window.location.replace('https://www.facebook.com/shuaaiq?mibextid=ZbWKwL')}>
            <img  src='/facebook.png'/>
            <p>facebook</p>
          </div>
{/*         
          <div className='social' onClick={()=> window.location.replace('https://t.snapchat.com/zKBs19zu')}>
            <img  src='/snapchat.png'/>
            <p>SnapChat</p>
          </div> */}
          <div className='social' onClick={()=>window.location.replace('http://shuaa-alkhaleej.com/')}>
            <img  src='/website.png'/>
            <p>WebSite</p>
          </div>
          <div className='social' onClick={()=> window.location.replace(`https://www.google.com/maps/place/33%C2%B024'20.1%22N+44%C2%B023'09.5%22E/@33.4055895,44.3833823,17z/data=!3m1!4b1!4m4!3m3!8m2!3d33.405585!4d44.3859572?entry=ttu`)}>
            <img  src='/locaiton.png'/>
            <p>Location</p>
          </div>
         
         
         
          <div className='social' onClick={()=> window.location.replace('https://apps.apple.com/us/app/shuaa-alkhaleej/id1615960590')}>
            <img  src='/3.png'/>
            <p>App Store</p>
          </div>
          
          <div className='social' onClick={()=> window.location.replace('https://play.google.com/store/apps/details?id=com.sdnone.samkari')}>
            <img  src='/4.png'/>
            <p>Google Play</p>
          </div>
        </div>
         
    </div>
    </>

    
  );
}

export default App;
